.policy-violation p
{
    margin-top: 0.5rem;
    margin-bottom: 0px;
    /* line-height: 150%; */
    
}

.policy-violation span, span.filter-pill.policy-violations p
{
    padding: 2px 12px;
    background-color: var(--POLICY-VIOLATIONS-BG);
    color: var(--POLICY-VIOLATIONS-TEXT);
    border-radius: 2rem;
    margin-left: 0.25rem;
    margin-top: 0.5rem;
}

span.filter-pill.policy-violations p
{
    margin-left: 0rem;
    width: max-content;
}

.policy-violation p.link
{
    color: blue;
    cursor: pointer;
}

.policy-violation p.link:hover
{
    text-decoration: underline;
}
