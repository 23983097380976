.network-card .modal-content
{
    background-color: #E9E9E9;
    padding: 1rem;
}

.network-card h1
{
    font-size: 2rem;
    font-weight: 700;
}

.card-wrapper
{
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.card-wrapper .flex-5
{
    width: 50%;
    overflow: auto;
}

.card-wrapper .sub-title
{
    font-weight: 700;
    color: #8B8B8B;
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.card-wrapper .named-entity-tag:first-of-type
{
    margin-left: 0px;
}

.card-wrapper .module
{
    margin-bottom: 1rem;
}

.card-wrapper p
{
    margin-left: 0px;
}

.card-wrapper .dates
{
    margin-bottom: 1rem;
    max-width: 100%;
    width: 100%;

}

.card-wrapper .dates span::after
{
    content: ", "
}

.card-wrapper .dates span:last-of-type:after
{
    content: ""
}


.disclosure-card-wrapper
{
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.65);
    background: #F5F5F5;
    margin-bottom: 1.5rem;
    border-radius: 10px;
}

.disclosure-card-wrapper:first-of-type
{
    margin-top: 2rem;
}

.disclosure-card-wrapper .header
{
    cursor: pointer;
}