.disclosure-card
{
    padding: 1rem;
}

.disclosure-card h1
{
    margin-bottom: 0px;
}

.disclosure-card h5
{
    margin-bottom: 0px;
    margin-right: 1rem;
}

.card-wrapper .policy-violation-tag:first-of-type
{
    margin-left: 0px;
}

.disclosure-card-wrapper .header>h1 img
{
    max-width: 300px;
    max-height: 50px;
    width: auto;
    height: auto;
}



/* For Mobile Screens */
@media (max-width: 760px) {

    .network-card.modal .card-wrapper
    {
        flex-direction: column;
    }

    .network-card.modal .card-wrapper>div
    {
        width: 100%;
    }

    .disclosure-card-wrapper .header
    {
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
    }

    .disclosure-card-wrapper .header>h1
    {
        width: 100%;
    }

    .disclosure-card-wrapper .header>h1 img
    {
        max-width: 80%;
        margin-bottom: 1rem;
    }

    .disclosure-card-wrapper .header>div
    {
        width: 100%;
        justify-content: space-between;
    }
}