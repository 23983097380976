.filter-pill
{
    display: inline-block;
    margin-right: 0.5rem;
    margin-top: 5px;
    font-family: 'Roboto Mono';
}

.data-viewer-text p
{
    font-size: 0.95rem;
}