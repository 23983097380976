#contents
{
    display: flex;
    justify-content: center;
}

.lst-kix_zmhx99cbuyq-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_2d8m2c6qqbkf-1 {
    list-style-type: none
}

ul.lst-kix_2d8m2c6qqbkf-0 {
    list-style-type: none
}

.lst-kix_zmhx99cbuyq-0>li:before {
    content: "\0025cf  "
}

.lst-kix_zmhx99cbuyq-1>li:before {
    content: "\0025cb  "
}

.lst-kix_sqght1vndnda-1>li:before {
    content: "\0025cb  "
}

.lst-kix_sqght1vndnda-0>li:before {
    content: "\0025cf  "
}

.lst-kix_4k2xl69enp6p-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_2d8m2c6qqbkf-7 {
    list-style-type: none
}

ul.lst-kix_2d8m2c6qqbkf-6 {
    list-style-type: none
}

ul.lst-kix_2d8m2c6qqbkf-8 {
    list-style-type: none
}

ul.lst-kix_2d8m2c6qqbkf-3 {
    list-style-type: none
}

ul.lst-kix_2d8m2c6qqbkf-2 {
    list-style-type: none
}

ul.lst-kix_2d8m2c6qqbkf-5 {
    list-style-type: none
}

ul.lst-kix_2d8m2c6qqbkf-4 {
    list-style-type: none
}

.lst-kix_jl6qmxdmc61p-8>li:before {
    content: "\0025a0  "
}

.lst-kix_jl6qmxdmc61p-7>li:before {
    content: "\0025cb  "
}

.lst-kix_jl6qmxdmc61p-5>li:before {
    content: "\0025a0  "
}

.lst-kix_jl6qmxdmc61p-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_h8skkn93z7vq-0 {
    list-style-type: none
}

ul.lst-kix_h8skkn93z7vq-1 {
    list-style-type: none
}

ul.lst-kix_h8skkn93z7vq-2 {
    list-style-type: none
}

ul.lst-kix_h8skkn93z7vq-3 {
    list-style-type: none
}

.lst-kix_jl6qmxdmc61p-0>li:before {
    content: "\0025cf  "
}

.lst-kix_jl6qmxdmc61p-1>li:before {
    content: "\0025cb  "
}

.lst-kix_jl6qmxdmc61p-4>li:before {
    content: "\0025cb  "
}

.lst-kix_jl6qmxdmc61p-3>li:before {
    content: "\0025cf  "
}

.lst-kix_jl6qmxdmc61p-2>li:before {
    content: "\0025a0  "
}

.lst-kix_sqght1vndnda-7>li:before {
    content: "\0025cb  "
}

.lst-kix_sqght1vndnda-4>li:before {
    content: "\0025cb  "
}

.lst-kix_sqght1vndnda-8>li:before {
    content: "\0025a0  "
}

.lst-kix_sqght1vndnda-3>li:before {
    content: "\0025cf  "
}

.lst-kix_sqght1vndnda-2>li:before {
    content: "\0025a0  "
}

.lst-kix_sqght1vndnda-5>li:before {
    content: "\0025a0  "
}

.lst-kix_sqght1vndnda-6>li:before {
    content: "\0025cf  "
}

.lst-kix_3dm2a9cn64mm-8>li:before {
    content: "\0025a0  "
}

.lst-kix_3dm2a9cn64mm-4>li:before {
    content: "\0025cb  "
}

.lst-kix_3dm2a9cn64mm-6>li:before {
    content: "\0025cf  "
}

.lst-kix_h8skkn93z7vq-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_h8skkn93z7vq-4 {
    list-style-type: none
}

ul.lst-kix_h8skkn93z7vq-5 {
    list-style-type: none
}

ul.lst-kix_h8skkn93z7vq-6 {
    list-style-type: none
}

.lst-kix_3dm2a9cn64mm-0>li:before {
    content: "\0025cf  "
}

.lst-kix_3dm2a9cn64mm-2>li:before {
    content: "\0025a0  "
}

.lst-kix_grif655x4puz-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_h8skkn93z7vq-7 {
    list-style-type: none
}

.lst-kix_h8skkn93z7vq-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_h8skkn93z7vq-8 {
    list-style-type: none
}

.lst-kix_grif655x4puz-1>li:before {
    content: "\0025cb  "
}

.lst-kix_h8skkn93z7vq-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_gqhugt66gsak-8 {
    list-style-type: none
}

.lst-kix_2d8m2c6qqbkf-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_gqhugt66gsak-7 {
    list-style-type: none
}

ul.lst-kix_sqght1vndnda-2 {
    list-style-type: none
}

ul.lst-kix_sqght1vndnda-3 {
    list-style-type: none
}

ul.lst-kix_grif655x4puz-1 {
    list-style-type: none
}

ul.lst-kix_sqght1vndnda-4 {
    list-style-type: none
}

ul.lst-kix_grif655x4puz-0 {
    list-style-type: none
}

ul.lst-kix_sqght1vndnda-5 {
    list-style-type: none
}

ul.lst-kix_grif655x4puz-3 {
    list-style-type: none
}

ul.lst-kix_grif655x4puz-2 {
    list-style-type: none
}

ul.lst-kix_grif655x4puz-5 {
    list-style-type: none
}

ul.lst-kix_sqght1vndnda-0 {
    list-style-type: none
}

.lst-kix_2d8m2c6qqbkf-4>li:before {
    content: "\0025cb  "
}

ul.lst-kix_grif655x4puz-4 {
    list-style-type: none
}

ul.lst-kix_sqght1vndnda-1 {
    list-style-type: none
}

ul.lst-kix_grif655x4puz-7 {
    list-style-type: none
}

.lst-kix_a7gji5rn3oy8-4>li:before {
    content: "\0025cb  "
}

ul.lst-kix_grif655x4puz-6 {
    list-style-type: none
}

.lst-kix_grif655x4puz-5>li:before {
    content: "\0025a0  "
}

ul.lst-kix_grif655x4puz-8 {
    list-style-type: none
}

.lst-kix_h8skkn93z7vq-4>li:before {
    content: "\0025cb  "
}

.lst-kix_a7gji5rn3oy8-6>li:before {
    content: "\0025cf  "
}

.lst-kix_4k2xl69enp6p-2>li:before {
    content: "\0025a0  "
}

.lst-kix_h8skkn93z7vq-6>li:before {
    content: "\0025cf  "
}

.lst-kix_a7gji5rn3oy8-8>li:before {
    content: "\0025a0  "
}

.lst-kix_grif655x4puz-7>li:before {
    content: "\0025cb  "
}

.lst-kix_4k2xl69enp6p-4>li:before {
    content: "\0025cb  "
}

.lst-kix_2d8m2c6qqbkf-0>li:before {
    content: "\0025cf  "
}

.lst-kix_4k2xl69enp6p-6>li:before {
    content: "\0025cf  "
}

.lst-kix_gqhugt66gsak-8>li:before {
    content: "\0025a0  "
}

.lst-kix_gqhugt66gsak-4>li:before {
    content: "\0025cb  "
}

.lst-kix_4k2xl69enp6p-8>li:before {
    content: "\0025a0  "
}

.lst-kix_zmhx99cbuyq-8>li:before {
    content: "\0025a0  "
}

.lst-kix_zmhx99cbuyq-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_sqght1vndnda-6 {
    list-style-type: none
}

.lst-kix_a7gji5rn3oy8-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_sqght1vndnda-7 {
    list-style-type: none
}

ul.lst-kix_sqght1vndnda-8 {
    list-style-type: none
}

.lst-kix_a7gji5rn3oy8-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_gqhugt66gsak-6 {
    list-style-type: none
}

ul.lst-kix_gqhugt66gsak-5 {
    list-style-type: none
}

ul.lst-kix_gqhugt66gsak-4 {
    list-style-type: none
}

ul.lst-kix_gqhugt66gsak-3 {
    list-style-type: none
}

.lst-kix_gqhugt66gsak-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_gqhugt66gsak-2 {
    list-style-type: none
}

ul.lst-kix_gqhugt66gsak-1 {
    list-style-type: none
}

.lst-kix_zmhx99cbuyq-4>li:before {
    content: "\0025cb  "
}

ul.lst-kix_gqhugt66gsak-0 {
    list-style-type: none
}

.lst-kix_gqhugt66gsak-0>li:before {
    content: "\0025cf  "
}

.lst-kix_gqhugt66gsak-2>li:before {
    content: "\0025a0  "
}

.lst-kix_gqhugt66gsak-1>li:before {
    content: "\0025cb  "
}

.lst-kix_2d8m2c6qqbkf-7>li:before {
    content: "\0025cb  "
}

.lst-kix_2d8m2c6qqbkf-5>li:before {
    content: "\0025a0  "
}

.lst-kix_2d8m2c6qqbkf-6>li:before {
    content: "\0025cf  "
}

.lst-kix_2d8m2c6qqbkf-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_a7gji5rn3oy8-5 {
    list-style-type: none
}

ul.lst-kix_a7gji5rn3oy8-6 {
    list-style-type: none
}

ul.lst-kix_a7gji5rn3oy8-7 {
    list-style-type: none
}

ul.lst-kix_a7gji5rn3oy8-8 {
    list-style-type: none
}

ul.lst-kix_4k2xl69enp6p-6 {
    list-style-type: none
}

.lst-kix_fw8uh1wwswqz-7>li:before {
    content: "\0025cb  "
}

ul.lst-kix_4k2xl69enp6p-7 {
    list-style-type: none
}

ul.lst-kix_4k2xl69enp6p-8 {
    list-style-type: none
}

.lst-kix_fw8uh1wwswqz-6>li:before {
    content: "\0025cf  "
}

.lst-kix_fw8uh1wwswqz-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_4k2xl69enp6p-2 {
    list-style-type: none
}

ul.lst-kix_4k2xl69enp6p-3 {
    list-style-type: none
}

ul.lst-kix_4k2xl69enp6p-4 {
    list-style-type: none
}

ul.lst-kix_4k2xl69enp6p-5 {
    list-style-type: none
}

ul.lst-kix_a7gji5rn3oy8-1 {
    list-style-type: none
}

ul.lst-kix_a7gji5rn3oy8-2 {
    list-style-type: none
}

ul.lst-kix_a7gji5rn3oy8-3 {
    list-style-type: none
}

.lst-kix_fw8uh1wwswqz-4>li:before {
    content: "\0025cb  "
}

ul.lst-kix_a7gji5rn3oy8-4 {
    list-style-type: none
}

.lst-kix_fw8uh1wwswqz-5>li:before {
    content: "\0025a0  "
}

ul.lst-kix_a7gji5rn3oy8-0 {
    list-style-type: none
}

.lst-kix_fw8uh1wwswqz-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_jl6qmxdmc61p-8 {
    list-style-type: none
}

ul.lst-kix_jl6qmxdmc61p-6 {
    list-style-type: none
}

ul.lst-kix_jl6qmxdmc61p-7 {
    list-style-type: none
}

ul.lst-kix_jl6qmxdmc61p-4 {
    list-style-type: none
}

.lst-kix_fw8uh1wwswqz-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_jl6qmxdmc61p-5 {
    list-style-type: none
}

ul.lst-kix_4k2xl69enp6p-0 {
    list-style-type: none
}

ul.lst-kix_jl6qmxdmc61p-2 {
    list-style-type: none
}

.lst-kix_fw8uh1wwswqz-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_4k2xl69enp6p-1 {
    list-style-type: none
}

ul.lst-kix_jl6qmxdmc61p-3 {
    list-style-type: none
}

ul.lst-kix_jl6qmxdmc61p-0 {
    list-style-type: none
}

.lst-kix_fw8uh1wwswqz-1>li:before {
    content: "\0025cb  "
}

ul.lst-kix_jl6qmxdmc61p-1 {
    list-style-type: none
}

.lst-kix_3dm2a9cn64mm-7>li:before {
    content: "\0025cb  "
}

.lst-kix_3dm2a9cn64mm-5>li:before {
    content: "\0025a0  "
}

.lst-kix_grif655x4puz-4>li:before {
    content: "\0025cb  "
}

.lst-kix_3dm2a9cn64mm-1>li:before {
    content: "\0025cb  "
}

.lst-kix_h8skkn93z7vq-1>li:before {
    content: "\0025cb  "
}

.lst-kix_3dm2a9cn64mm-3>li:before {
    content: "\0025cf  "
}

.lst-kix_grif655x4puz-2>li:before {
    content: "\0025a0  "
}

.lst-kix_grif655x4puz-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_zmhx99cbuyq-0 {
    list-style-type: none
}

ul.lst-kix_zmhx99cbuyq-1 {
    list-style-type: none
}

.lst-kix_2d8m2c6qqbkf-1>li:before {
    content: "\0025cb  "
}

ul.lst-kix_zmhx99cbuyq-2 {
    list-style-type: none
}

ul.lst-kix_zmhx99cbuyq-3 {
    list-style-type: none
}

ul.lst-kix_zmhx99cbuyq-4 {
    list-style-type: none
}

ul.lst-kix_zmhx99cbuyq-5 {
    list-style-type: none
}

.lst-kix_2d8m2c6qqbkf-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_zmhx99cbuyq-6 {
    list-style-type: none
}

ul.lst-kix_zmhx99cbuyq-7 {
    list-style-type: none
}

ul.lst-kix_zmhx99cbuyq-8 {
    list-style-type: none
}

.lst-kix_h8skkn93z7vq-3>li:before {
    content: "\0025cf  "
}

.lst-kix_a7gji5rn3oy8-5>li:before {
    content: "\0025a0  "
}

.lst-kix_4k2xl69enp6p-1>li:before {
    content: "\0025cb  "
}

.lst-kix_grif655x4puz-6>li:before {
    content: "\0025cf  "
}

.lst-kix_a7gji5rn3oy8-7>li:before {
    content: "\0025cb  "
}

.lst-kix_h8skkn93z7vq-5>li:before {
    content: "\0025a0  "
}

.lst-kix_h8skkn93z7vq-7>li:before {
    content: "\0025cb  "
}

.lst-kix_4k2xl69enp6p-3>li:before {
    content: "\0025cf  "
}

.lst-kix_grif655x4puz-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_fw8uh1wwswqz-5 {
    list-style-type: none
}

ul.lst-kix_fw8uh1wwswqz-6 {
    list-style-type: none
}

ul.lst-kix_3dm2a9cn64mm-0 {
    list-style-type: none
}

ul.lst-kix_fw8uh1wwswqz-7 {
    list-style-type: none
}

ul.lst-kix_3dm2a9cn64mm-1 {
    list-style-type: none
}

ul.lst-kix_fw8uh1wwswqz-8 {
    list-style-type: none
}

.lst-kix_4k2xl69enp6p-5>li:before {
    content: "\0025a0  "
}

.lst-kix_gqhugt66gsak-5>li:before {
    content: "\0025a0  "
}

ul.lst-kix_3dm2a9cn64mm-6 {
    list-style-type: none
}

ul.lst-kix_3dm2a9cn64mm-7 {
    list-style-type: none
}

ul.lst-kix_3dm2a9cn64mm-8 {
    list-style-type: none
}

ul.lst-kix_fw8uh1wwswqz-0 {
    list-style-type: none
}

ul.lst-kix_3dm2a9cn64mm-2 {
    list-style-type: none
}

ul.lst-kix_fw8uh1wwswqz-1 {
    list-style-type: none
}

ul.lst-kix_3dm2a9cn64mm-3 {
    list-style-type: none
}

ul.lst-kix_fw8uh1wwswqz-2 {
    list-style-type: none
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_4k2xl69enp6p-7>li:before {
    content: "\0025cb  "
}

ul.lst-kix_3dm2a9cn64mm-4 {
    list-style-type: none
}

ul.lst-kix_fw8uh1wwswqz-3 {
    list-style-type: none
}

ul.lst-kix_3dm2a9cn64mm-5 {
    list-style-type: none
}

.lst-kix_gqhugt66gsak-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_fw8uh1wwswqz-4 {
    list-style-type: none
}

.lst-kix_zmhx99cbuyq-7>li:before {
    content: "\0025cb  "
}

.lst-kix_a7gji5rn3oy8-3>li:before {
    content: "\0025cf  "
}

.lst-kix_zmhx99cbuyq-3>li:before {
    content: "\0025cf  "
}

.lst-kix_a7gji5rn3oy8-1>li:before {
    content: "\0025cb  "
}

.lst-kix_zmhx99cbuyq-5>li:before {
    content: "\0025a0  "
}

.lst-kix_gqhugt66gsak-7>li:before {
    content: "\0025cb  "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c1 {
    margin-left: 72pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    ;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Roboto";
    font-style: normal
}

.c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    margin-bottom: 0.25rem;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Roboto";
    font-style: normal
}

.c12 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 2.5rem;
    font-family: "Roboto";
    font-style: normal
}

.c14 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Roboto";
    font-style: normal
}

.c11 {
    color: #000000;
    font-weight: 400;
    vertical-align: baseline;
    font-family: "Roboto";
    font-style: normal
}

.c10 {
    background-color: #ffffff;
    max-width: 90%;
    /* padding: 72pt 72pt 72pt 72pt */
}

.c6 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline
}

.c9 {
    margin-left: 36pt;
    padding-left: 0pt
}

.c8 {
    padding: 0;
    margin: 0
}

.c15 {
    color: inherit;
    text-decoration: inherit
}

.c2 {
    font-size: 1.15rem
    
}

.c13 {
    font-size: 1.5rem;
    /* margin-bottom: 3rem   */
}

.c3 {
    font-weight: 500;
    /* margin-bottom: 2rem; */

}

.c5 {
    height: 11pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 2.5rem;
    padding-bottom: 3pt;
    font-family: "Roboto";
    ;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 1.5rem;
    padding-bottom: 16pt;
    font-family: "Roboto";
    ;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 1rem;
    font-family: "Roboto"
}

p {
    margin: 0;
    color: #000000;
    font-size: 1rem;
    font-family: "Roboto"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 3rem;
    padding-bottom: 6pt;
    font-family: "Roboto";
    ;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 2rem;
    padding-bottom: 6pt;
    font-family: "Roboto";
    ;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 1rem;
    padding-bottom: 4pt;
    font-family: "Roboto";
    ;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 1rem;
    padding-bottom: 4pt;
    font-family: "Roboto";
    ;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Roboto";
    ;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Roboto";
    ;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}