.card-body-row
{
    /* flex: 1 0 50%; */
    width: 50%;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    height: 400px;
    margin-bottom: 2rem;
    border-radius: 5px;
}

.card-body-row .screenshots
{
    /* flex: 2 0 40%; */
    overflow: hidden;
    width: 40%;
    display: inline-block;
}


.card-body-row .content
{
    /* flex: 3 0 60%; */
    width: 60%;
    display: inline-block;
    /* padding: 1rem; */
    padding-left: 1rem;
    padding-top: 1rem;
    cursor: pointer;
    font-size: 0.95rem;
    overflow: auto;
}

.card-body
{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
}

.card-body-cell
{
    flex: 1 0 100%
}

.card-body-cell .target-country p, .card-body-cell .origin-country p, .card-body-cell .disclosure-date p, .card-body-cell .source span
{
    margin-left: 0rem;
    display: inline-block;
    margin-right: 0.25rem;
}

.card-body-cell .source span
{
    margin-right: 0.5rem;
    margin-top: 0px;
    font-size: 0.8rem;
}

.card-body-row>div
{
    /* border: 1px solid black; */
    box-shadow: 0 0 5px rgb(0 0 0 / 25%);
    border-radius: 5px;
    margin-bottom: 0rem;
    margin-top: 0rem;
    height: 100%;
    overflow: hidden;
    width: 100%;
    display: flex;
}

.card-body-row .sub-title
{
    font-weight: 400;
    color: #8B8B8B;
    font-size: 0.75rem;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
}

.card-body-row>div:hover
{
    transform: scale(1.01);
    box-shadow: 0 0 15px rgba(0,0,0,.25);
    transition: .3s;
}

@media (max-width: 1024px) {
    .card-body-row
    {
        width: 100%;
    }

    .card-body-row .screenshots
    {
        width: 50%;
        min-height: 250px;
    }

    .card-body-row .content
    {
        width: 50%;
        min-height: 250px;
        display: flex;
        flex-wrap: wrap;
    }

    .card-body-cell
    {
        flex: 1 0 50%
    }
}

/* For Mobile Screens */

@media (max-width: 760px) {
    .table
    {
        flex-direction: column;
    }

    .card-body-row > div
    {
        flex-direction: column;
    }

    .card-body-row .screenshots
    {
        width: 100%;
        min-height: 250px;
    }

    .card-body-row .content
    {
        width: 100%;
        min-height: 250px;
    }

    .card-body-row
    {
        height: 650px;
    }

    .card-body-cell
    {
        flex: 1 0 100%
    }
}