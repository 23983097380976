.source p
{
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Roboto Mono';
    cursor: pointer

}

.source span
{
    padding: 2px 12px;
    background-color: rgb(226, 226, 226);
    border-radius: 2rem;
    margin-left: 0.25rem;
    margin-top: 0rem;
    margin-bottom: 5px;
    cursor: pointer

}

.source p.link
{
    color: blue;
    cursor: pointer;
}

.source p.link:hover
{
    text-decoration: underline;
}



.filter-pill.source p
{
    width: max-content;
    padding: 2px 12px;
    border-radius: 2rem;
    margin-bottom: 0px;
    background-color: rgba(217, 203, 189, 1);
}

.source span.facebook, .filter-pill.source.facebook p
{
    color: var(--FACEBOOK-COLOR);
    background-color: var(--FACEBOOK-BG-COLOR);
}

.source span.reddit, .filter-pill.source.reddit p
{
    color: var(--REDDIT-COLOR);
    background-color: var(--REDDIT-BG-COLOR);
}

.source span.graphika, .filter-pill.source.graphika p
{
    color: var(--GRAPHIKA-COLOR);
    background-color: var(--GRAPHIKA-BG-COLOR);
}

.source span.google, .filter-pill.source.google p
{
    color: var(--GOOGLE-COLOR);
    background-color: var(--GOOGLE-BG-COLOR);
}

.source span.twitter, .filter-pill.source.twitter p
{
    color: var(--TWITTER-COLOR);
    background-color: var(--TWITTER-BG-COLOR);
}

.filter-pill.source
{
    padding: 0rem;
    border-radius: 2rem;
    background-color: transparent;
}


.filter-pill.source-type p, .filter-pill.removal-types p, .filter-pill.dates p, .filter-pill.screenshots p

{
    width: max-content;
    padding: 2px 12px;
    border-radius: 2rem;
    margin-bottom: 5px;
    background-color: rgba(217, 203, 189, 1);
    color: black;
}

