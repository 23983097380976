.target-country p, .target-countries p

{
    margin-top: 0rem;
    margin-bottom: 5px;
    font-family: 'Roboto Mono';
    padding: 2px 12px;
    background-color: rgba(0, 55, 255, 0.2);
    color: #0038FF;
    border-radius: 2rem;
    margin-left: 0.25rem;
    width: fit-content;
    cursor: pointer
}

.target-country p.link
{
    color: black;
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
}

.target-country p.link:hover
{
    /* text-decoration: underline; */
}