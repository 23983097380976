.named-entity p
{
    margin-top: 0rem;
    margin-bottom: 0px;
    font-family: 'Roboto Mono';
    cursor: pointer
    /* line-height: 150%; */
    
}

.named-entity span, .filter-pill.named-entities p

{
    padding: 2px 12px;
    background-color: var(--NAMED-ENTITIES-BG);
    color: var(--NAMED-ENTITIES-TEXT);
    border-radius: 2rem;
    margin-left: 0.25rem;
    margin-bottom: 5px;
    width: max-content;

}

span.filter-pill.named-entities p
{
    margin-left: 0rem;
}

.named-entity p.link
{
    color: blue;
    cursor: pointer;
}

.named-entity p.link:hover
{
    text-decoration: underline;
}

.card-body-cell.Named
{
    /* width: 90%; */
}

.card-body-cell.Named p
{
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 1024px)
{
    .card-body-cell.Named
    {
        flex: 1 0 90%;
    }
}
