.go-to-page
{
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    padding: 0.35rem;
    min-width: 1rem;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    font-family: 'Roboto';

}

.go-to-page:hover
{
    font-weight: bold;
    background-color: rgba(217, 203, 189, 0.8);
}

.go-to-page.active
{
    font-weight: bold;
    background-color: rgba(217, 203, 189, 1);
}

.go-to-page-wrapper
{
    display: inline-block;
}

.pagination-button
{
    color: #BBA38C;
    border: 1px solid #BBA38C;
    border-radius: 3px;
    font-size: 0.85rem;
    background-color: white;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
}

.pagination-button:hover
{
    background-color: #f7f7f7;
}

#pagination
{
    margin-top: 2rem;
    font-family: 'Roboto';
}


/* For Mobile Screens */

@media (max-width: 1024px) {

.pagination-wrapper
{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.pagination-wrapper>button:first-of-type
{
    order: 1;
    margin-top: 1rem;
    min-width: 30%;
}

.go-to-page-wrapper
{
    display: flex;
    flex-basis: 100%;
    order: 0;
    justify-content: center;
    margin-top: 1rem;
}

.pagination-wrapper>button:last-of-type
{
    order: 2;
    margin-top: 1rem;
    min-width: 30%;
}
}