.date-filter-wrapper
{
    min-height: 38px;
    border: 1px solid hsl(0,0%,80%);
    border-radius: 4px;
    align-items: center;
    display: flex;
    overflow-x: auto;
    width: 100%;
    /* flex-direction: column; */
}
.date-filter-wrapper p
{
    font-size: 16px;
    padding: 2px 12px;
    margin-bottom: 0px;
    outline: 0 ;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
}

.date-filter-wrapper span
{
    overflow-x: auto;
}

.date-range-picker
{
    display: flex;
    justify-content: center;
}

.date-range-picker>div
{
    justify-content: center;
    padding: 1rem;
}

.date-range-picker label
{
    text-align: center;
    width: 100%;
}

.date-range-picker input
{
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    padding: 2px 12px;
    border: 1px solid hsl(0,0%,80%);
    min-height: 38px;

}

.network-table-filters
{
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.filters
{
    color: #8b8b8b;
    font-weight: 400;
    margin-top: 1rem;
    display: block;
    font-family: 'Roboto';
    font-size: 0.85rem;
}
.filters>div
{
    /* flex: 1 0 */
    width: 20%;
    display: inline-block;
    padding: 0.5rem;
}

.select-option
{
    padding: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
    width: 100%;
}


.select-option:hover
{
    background-color: #ccc;
}

@media (min-width: 1201px) {
    .filters>div
    {
        max-width: 250px;
    }
}

/* For Tablet Screens and below */
@media (max-width: 1024px) {
    .filters {
        display: flex;
        flex-wrap: wrap;
    }

    .filters>div
    {
        width: 25%
    }
}


/* For Mobile Screens */

@media (max-width: 760px) {
    .filters {
        display: flex;
        flex-direction: column;
    }

    .filters>div
    {
        width: 100%
    }
}