.resizer {
    display: inline-block;
    background: rgba(217, 203, 189, 0.3);
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    /* transform: translateX(50%); */
    z-index: 1;
}

.isResizing {
    background: rgba(217, 203, 189, 1);
  }
  
.table-header
{
    /* display: flex; */
}

.table
{
    overflow-x: auto;
    border-right: 1px solid #cecece;
}

.table-body
{
    /* display: flex; */
    /* overflow-x: hidden;
    overflow-y: scroll; */
    /* border-right: 1px solid #cecece; */
    border-bottom: 1px solid #cecece;
}


.table-body-cell, .table-header-cell
{
    font-family: 'Roboto';
    font-size: 13px;
    overflow-x: auto;
    padding: 0.5rem;
    /* width: unset ; */
}

.table-header-row>div
{
    background-color: rgba(217, 203, 189, 0.2);
}


.table-body-row:hover
{
    background-color: rgba(217, 203, 189, 0.2);
}

.table-body-cell, .table-header-cell
{
    border-top: 1px solid #CECECE;
    border-left: 1px solid #CECECE;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.table-header-cell
{
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    min-height: 4rem;
    justify-content: space-between;
    align-items: center;
    /* font-size: 1rem; */
}


.table-header-cell span
{
    font-size: 1.2rem;
}

.table-header-cell span.active
{
    color: #0038FF;
    font-weight: bold;
}

.table-header-cell span, .table-body-cell span
{
    display: inline-block;
}
/* 
.table-body-cell.Network, .table-header-cell.Network
{
    min-width: 10% 
}

.table-body-cell.Disclosure, .table-header-cell.Disclosure
{
    min-width: 10% 
}

.table-body-cell.Source, .table-header-cell.Source
{
    min-width: 13%
}

.table-body-cell.Removal, .table-header-cell.Removal
{
    min-width: 15% 
}

.table-body-cell.Named, .table-header-cell.Named
{
    min-width: 22% 
}

.table-body-cell.Origin, .table-header-cell.Origin
{
    min-width: 15% 
}

.table-body-cell.Target, .table-header-cell.Target
{
    min-width: 15% 
} */



.table-header-cell
{
    font-weight: bold;
}


/* For Tablet Screens and below */

@media (max-width: 1024px) {
 
    .table
    {
        overflow: auto;
    }

    .table-body
    {
        width: max-content;
        min-width: 100%;
    }
    /* style={{"overflowX":"auto"}} */

    .table-body-cell.Network, .table-header-cell.Network
    {
        width: 120px ;
        /* width: 10%  */
    }
    
    .table-body-cell.Disclosure, .table-header-cell.Disclosure
    {
        width: 120px ;
        /* width: 10%  */
    }
    
    .table-body-cell.Source, .table-header-cell.Source
    {
        width: 200px ;
        /* width: 13%  */
    }
    
    .table-body-cell.Removal, .table-header-cell.Removal
    {
        width: 250px ;
        /* width: 15%  */
    }
    
    .table-body-cell.Named, .table-header-cell.Named
    {
        width: 300px ;
        /* width: 22%  */
    }
    
    .table-body-cell.Origin, .table-header-cell.Origin
    {
        width: 150px ;
        /* width: 15%  */
    }
    
    .table-body-cell.Target, .table-header-cell.Target
    {
        width: 150px ;
        /* width: 15%  */
    }

   
}