#switch-toggler
{
    margin-top: 1rem;
    justify-content: flex-end;
}

#switch-toggler img
{
    margin-left: 1rem;
    margin-right: 1rem;
}

#switch-toggler .toggle
{
    cursor: pointer;
}

@media (max-width: 1024px)
{
    #switch-toggler
    {
        justify-content: center;
        flex-direction: row;
    }
}