@font-face {
  font-family: 'Frutiger';
  src: url('./assets/fonts/FrutigerLTStd-BlackItalic.eot');
  src: url('./assets/fonts/FrutigerLTStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/FrutigerLTStd-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/FrutigerLTStd-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger';
  src: url('./assets/fonts/FrutigerLTStd-Italic.eot');
  src: url('./assets/fonts/FrutigerLTStd-Italic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/FrutigerLTStd-Italic.woff2') format('woff2'),
      url('./assets/fonts/FrutigerLTStd-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger';
  src: url('./assets/fonts/FrutigerLTStd-Black.eot');
  src: url('./assets/fonts/FrutigerLTStd-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/FrutigerLTStd-Black.woff2') format('woff2'),
      url('./assets/fonts/FrutigerLTStd-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger';
  src: url('./assets/fonts/FrutigerLTStd-Roman.eot');
  src: url('./assets/fonts/FrutigerLTStd-Roman.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/FrutigerLTStd-Roman.woff2') format('woff2'),
      url('./assets/fonts/FrutigerLTStd-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Garamond';
  src: url('./assets/fonts/GaramondPremrPro-ItCapt.eot');
  src: url('./assets/fonts/GaramondPremrPro-ItCapt.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/GaramondPremrPro-ItCapt.woff2') format('woff2'),
      url('./assets/fonts/GaramondPremrPro-ItCapt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Garamond';
  src: url('./assets/fonts/GaramondPremrPro-Med.eot');
  src: url('./assets/fonts/GaramondPremrPro-Med.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/GaramondPremrPro-Med.woff2') format('woff2'),
      url('./assets/fonts/GaramondPremrPro-Med.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
